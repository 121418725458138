import React from 'react';
import PropTypes from 'prop-types';

const Chevron = ({ className }) => (
  <svg
    className={`${className} fill-current`}
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.776628 1.42259C0.451191 1.09715 0.451191 0.569515 0.776628 0.244078C1.10207 -0.0813592 1.6297 -0.0813592 1.95514 0.244078L6.95514 5.24408C7.27062 5.55956 7.28166 6.06755 6.98018 6.39644L2.39685 11.3964C2.08585 11.7357 1.55871 11.7586 1.21945 11.4476C0.880181 11.1366 0.857262 10.6095 1.16826 10.2702L5.21244 5.8584L0.776628 1.42259Z" />
  </svg>
);

Chevron.propTypes = {
  className: PropTypes.string,
};
Chevron.defaultProps = {
  className: 'h-4',
};

export default Chevron;
