import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Widget from './components/Widget/Widget';
import './i18n';

const urlParams = new URLSearchParams(document.location.search);
const pubId = urlParams.get('id');

const App = () =>
  pubId ? <Widget pubId={pubId} /> : <p>Please provide a pub id using the id param</p>;

const rootElement = document.getElementById('root');
ReactDOM.render(<App />, rootElement);

export default App;
