import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { add, format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import {
  usePaginatedRequest,
  request,
  getURLQuery,
  useDetectOutsideClick,
} from '../../utils/general';
import DateSelector from '../DateSelector/DateSelector';
import Logo from '../Logo/Logo';
import Chevron from '../Icons/Chevron';
import Cross from '../Icons/Cross';

const Widget = ({ pubId }) => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState();
  const [sports, setSports] = useState();
  const [selectedSport, setSelectedSport] = useState();
  const [dates, setDates] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);

  const getSports = useCallback(() => request(`/venues/${pubId}/fixture/widget/sports`), [pubId]);

  const getFixturesByDays = useCallback(
    () =>
      request(
        `/venues/${pubId}/fixture/widget/has-fixtures-by-day${getURLQuery({
          from: format(new Date(), 'yyyy-MM-dd'),
          to: format(add(new Date(), { weeks: 2 }), 'yyyy-MM-dd'),
        })}`,
      ),
    [pubId],
  );

  const { data, getData, loading, hasMorePages, error } = usePaginatedRequest(
    `/venues/${pubId}/fixture/widget-json`,
    10,
    {
      localDateUtc: selectedDate,
      sportId: selectedSport?.id,
    },
  );

  useEffect(() => {
    getSports().then(s => setSports(s));
    getFixturesByDays().then(d => {
      setDates(d);
      if (d.find(e => e.hasFixture)) {
        setSelectedDate(d.filter(e => e.hasFixture)[0]?.date);
      } else {
        setSelectedDate(d[0]?.date);
      }
    });
  }, [getFixturesByDays, getSports]);

  useEffect(() => {
    if (selectedDate) {
      getData(true).then(() => setInitialLoad(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, selectedSport]);

  const logoRow = (
    <a target="_blank" rel="noreferrer" href="https://fanzo.com">
      <div className="flex items-center bg-black px-3 lg:justify-center">
        <p className="flex text-xs text-white">{t('powered')}</p>
        <Logo />
      </div>
    </a>
  );

  if (initialLoad) {
    return <LoadingWheel />;
  }

  return (
    <div className="bg-white px-4 py-12 lg:px-12">
      <div className="w-full">
        {sports && (
          <DropdownFilter
            selectedSport={selectedSport}
            updateSelectedSport={setSelectedSport}
            sports={sports}
            showClearButton={!selectedSport}
            t={t}
          />
        )}
        <DateSelector
          dates={dates}
          selectedDate={selectedDate}
          onPressDate={d => setSelectedDate(d)}
        />
        {error && (
          <div className="flex items-center justify-center">
            <p>{t('error')}</p>
          </div>
        )}
        {!loading && !initialLoad && data.length === 0 && !error ? (
          <div className="flex h-96 items-center justify-center bg-grey p-12">
            <p className="text-xl font-bold lg:text-3xl">
              {t('no_fixtures', { sport: !selectedSport ? '' : selectedSport?.name })}
            </p>
          </div>
        ) : (
          <>
            <FixtureList fixtures={data} loading={loading} />
            {hasMorePages && !loading && (
              <button
                onClick={() => getData(false)}
                type="button"
                className="rounded-md bg-black p-2 text-white"
              >
                {t('load_more')}
              </button>
            )}
          </>
        )}
        <div className="md:hidden">{logoRow}</div>
      </div>
      <div className="flex justify-between">
        <p className="bg-white py-2 text-xs">{t('copyright')}</p>
        <div className="hidden w-1/3 justify-end md:flex lg:w-1/4">{logoRow}</div>
      </div>
    </div>
  );
};

const FixtureList = ({ fixtures, loading }) => {
  const { t } = useTranslation();
  const teamTab = (teamName, logo, reverseOnDesktop) => (
    <div className={`flex items-center ${reverseOnDesktop ? 'lg:flex-row-reverse' : ''}`}>
      {logo && (
        <img
          src={logo}
          alt=""
          className="m-1 h-6 w-6 object-contain sm:m-2 sm:h-10 sm:w-10 lg:m-3 lg:h-16 lg:w-16"
        />
      )}
      <p className="ml-2 text-sm font-bold sm:text-xl md:ml-3 lg:text-[28px]">{teamName}</p>
    </div>
  );

  const fixtureTab = (fixture, showImage) => {
    const oneTeamEvent = fixture?.teams?.length !== 2;

    return (
      fixture && (
        <div
          className="my-3 items-start rounded-lg bg-lightGrey pb-2 md:flex md:pb-0"
          key={fixture?.id}
        >
          {showImage && (
            <img
              src={fixture?.image}
              alt=""
              className="rounded-t-lg object-cover md:h-[355px] md:w-1/2 md:rounded-l-lg md:rounded-tr-none"
            />
          )}
          <div
            className={`flex flex-1 flex-col items-start p-2 md:p-4 lg:p-8 ${
              showImage ? '' : 'lg:items-center'
            }`}
          >
            <p className="mb-3 rounded-xl bg-[rgb(0,0,0)]/[0.04] px-3 py-1 text-xs font-bold sm:text-base md:text-xl">{`${format(
              parseISO(fixture?.startTimeUtc),
              'HH:mm',
            )} • ${fixture?.competition?.name}`}</p>
            {oneTeamEvent &&
              (fixture.teams[0]
                ? teamTab(fixture?.teams[0].name, fixture?.teams[0]?.logo, false)
                : teamTab(fixture?.name, fixture?.sport?.roundImage, false))}

            {!oneTeamEvent && !showImage && (
              <div className="items-center lg:flex">
                {teamTab(fixture.teams[0].name, fixture.teams[0].logo, false)}
                <p className="hidden pl-4 text-4xl opacity-30 lg:block">VS</p>
                {teamTab(fixture.teams[1].name, fixture.teams[1].logo, !showImage)}
              </div>
            )}
            {!oneTeamEvent && showImage && (
              <>
                {teamTab(fixture.teams[0].name, fixture.teams[0].logo, false)}
                {teamTab(fixture.teams[1].name, fixture.teams[1].logo, false)}
              </>
            )}

            {fixture.bookingLink && (
              <a
                className="mt-4 flex w-full items-center justify-between rounded-lg bg-white px-4 py-2 font-semibold md:text-lg lg:mt-6 lg:w-fit"
                target="_blank"
                rel="noreferrer"
                href={fixture.bookingLink}
              >
                <span className="pr-3">Book a table</span>
                <Chevron />
              </a>
            )}
          </div>
        </div>
      )
    );
  };

  const nextBigFixture = fixtures?.find(fixture => fixture?.venueEvent?.bigScreen) || null;

  if (loading) {
    return <LoadingWheel />;
  }

  return (
    <>
      {nextBigFixture && (
        <>
          <h2 className="py-4 text-xl font-bold md:text-2xl lg:py-6 lg:text-3xl">
            {t('next_game_bigscreen')}
          </h2>
          {fixtureTab(nextBigFixture, true)}
        </>
      )}
      {fixtures.length > 0 && (
        <>
          <h2 className="py-4 text-3xl font-bold lg:py-6">{t('sport_fixtures')}</h2>
          {fixtures?.map(fixture => fixtureTab(fixture))}
        </>
      )}
    </>
  );
};

const DropdownFilter = ({ selectedSport, updateSelectedSport, sports, showClearButton, t }) => {
  const [showFilters, setShowFilters] = React.useState(false);
  const ref = React.useRef(null);
  useDetectOutsideClick(ref, () => setShowFilters(false));

  const resetFilter = () => {
    updateSelectedSport(null);
    setShowFilters(false);
  };

  return (
    <div className="relative w-full" ref={ref}>
      <div>
        <button
          type="button"
          className="inline-flex w-full items-center justify-between rounded-md border border-lightGrey px-4 py-2 text-sm shadow-sm focus:outline-none"
          aria-expanded={showFilters}
          aria-haspopup="true"
          onClick={() => (showClearButton ? setShowFilters(!showFilters) : resetFilter())}
        >
          <p className="mr-8 line-clamp-1">{selectedSport?.name || t('filter_by_sport')}</p>
          {showClearButton ? (
            <Chevron
              className={`h-3 rounded-full transition-transform ${
                showFilters ? '-rotate-90' : 'rotate-90'
              }`}
            />
          ) : (
            <div className="rounded-full bg-primaryYellow p-1">
              <Cross color="black" size={12} />
            </div>
          )}
        </button>
      </div>

      {showFilters && (
        <div
          className="absolute left-0 z-10 mt-2 w-full rounded-md bg-white shadow-lg focus:outline-none"
          role="menu"
          aria-orientation="vertical"
        >
          <div className="flex flex-1 flex-col py-1">
            {sports?.map(s => (
              <button
                type="button"
                className={`mx-2 flex flex-1 p-2 text-left text-mpGrey hover:bg-primaryYellow focus:outline-none ${
                  selectedSport?.id === s?.id ? 'bg-primaryYellow' : ''
                }`}
                key={s?.id}
                onClick={() => {
                  updateSelectedSport(s);
                  setShowFilters(false);
                }}
              >
                {s.name}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

Widget.propTypes = {
  pubId: PropTypes.string.isRequired,
};

DropdownFilter.propTypes = {
  sports: PropTypes.arrayOf(PropTypes.shape).isRequired,
  updateSelectedSport: PropTypes.func.isRequired,
  selectedSport: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }),
  showClearButton: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

DropdownFilter.defaultProps = {
  selectedSport: null,
};

FixtureList.propTypes = {
  fixtures: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loading: PropTypes.bool.isRequired,
};

const LoadingWheel = () => (
  <div className="flex justify-center">
    <img
      className="mt-8 w-12"
      alt="Loading..."
      src="https://cdn.internal.matchpint.cloud/spinner.gif"
    />
  </div>
);

export default Widget;
