import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { format, isToday, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { enGB, fr, de, it } from 'date-fns/locale';
import styles from './DateSelector.module.scss';
import Chevron from '../Icons/Chevron';

export const getLocale = () => {
  switch (i18next.resolvedLanguage) {
    case 'fr':
      return fr;
    case 'de':
      return de;
    case 'it':
      return it;
    default:
      return enGB;
  }
};

const DateSelector = ({ dates, onPressDate, selectedDate }) => {
  const { t } = useTranslation();

  const getRelativeLocaleDate = date => (
    <div className="flex flex-col">
      <span className="w-full font-bold">
        {isToday(date) ? t('today') : format(date, 'EEE', { locale: getLocale() })}
      </span>
      {format(date, 'dd MMM', { locale: getLocale() })}
    </div>
  );

  const renderDate = d => (
    <button
      type="button"
      className={selectedDate === d ? styles.item_selected : styles.item}
      key={d}
      onClick={() => onPressDate(d)}
    >
      {getRelativeLocaleDate(parseISO(d))}
    </button>
  );

  const ref = React.useRef(null);

  const scroll = scrollOffset => {
    ref.current.scrollLeft += scrollOffset;
  };

  const chevronCommon = 'bg-white border-2 border-lightGrey p-3 rounded-full';

  return (
    <div className="my-6 flex w-full items-center justify-center">
      <button
        className={chevronCommon}
        aria-label="show previous"
        type="button"
        onClick={() => scroll(-80)}
      >
        <Chevron className="h-3 rotate-180 text-mpGrey" />
      </button>
      <div ref={ref} className={styles.buttonsWrapper}>
        {dates?.map(({ date }) => renderDate(date))}
      </div>

      <button
        className={chevronCommon}
        aria-label="show next"
        type="button"
        onClick={() => scroll(80)}
      >
        <Chevron className="h-3 text-mpGrey" />
      </button>
    </div>
  );
};

DateSelector.propTypes = {
  onPressDate: PropTypes.func.isRequired,
  selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  dates: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

DateSelector.defaultProps = {
  selectedDate: undefined,
};

export default DateSelector;
