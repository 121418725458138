import { useCallback, useState, useEffect } from 'react';

export const getURLQuery = (parameters = {}, stripNullParams = false) => {
  const BOOLEAN = 'boolean';
  const NUMBER = 'number';
  const OBJECT = 'object';
  const STRING = 'string';

  const params = [];
  const keys = Object.keys(parameters);
  keys.forEach(_key => {
    const key = [encodeURIComponent(_key)];
    if ((parameters[key] === null || parameters[key] === '') && !stripNullParams) {
      params.push(key);
    } else {
      switch (typeof parameters[key]) {
        case BOOLEAN:
          params.push(`${key}=${parameters[key] ? '1' : '0'}`);
          break;
        case NUMBER:
        case STRING:
          params.push(`${key}=${encodeURIComponent(parameters[key])}`);
          break;
        case OBJECT: {
          if (Array.isArray(parameters[key])) {
            params.push(parameters[key].map(param => `${key}[]=${param}`).join('&'));
          }
          break;
        }
        default:
          break;
      }
    }
  });

  return params.length === 0 ? '' : `?${params.join('&')}`;
};

export const useDetectOutsideClick = (ref, action) => {
  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        action(event);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, action]);
};

export const request = endpoint =>
  fetch(`${process.env.REACT_APP_DOMAIN}${endpoint}`)
    .then(r => {
      if (r.ok) {
        return r.json();
      }
      throw r;
    })
    .then(res => res?.result || res);

const initialState = {
  offset: 0,
  loading: false,
  hasMorePages: true,
  data: [],
  error: false,
};

export const usePaginatedRequest = (endpoint, limit = 10, extraParams = {}) => {
  const [state, setState] = useState(initialState);

  const { offset, loading, hasMorePages, data, error } = state;

  const req = useCallback(
    callOffset =>
      request(
        `${endpoint}${getURLQuery({
          limit,
          offset: callOffset,
          ...extraParams,
        })}`,
      )
        .then(newData =>
          setState(oldState => ({
            ...oldState,
            offset: oldState.offset + newData.length,
            data: [...oldState.data, ...newData],
            loading: false,
            hasMorePages: newData.length === limit,
          })),
        )
        .catch(() => setState(oldState => ({ ...oldState, error: true, loading: false }))),
    [endpoint, extraParams, limit],
  );

  const getData = (refresh = false) => {
    if (refresh) {
      setState({ ...initialState, loading: true, error: false });
      return req(0);
    }
    setState(oldState => ({ ...oldState, loading: true, error: false }));
    return req(offset);
  };

  return { data, hasMorePages, getData, loading, error };
};
