import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslations from './translations/en.json';
import ie from './translations/en-ie.json';
import aus from './translations/en-au.json';
import deTranslations from './translations/de.json';
import frTranslations from './translations/fr.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    order: ['querystring', 'navigator'],
    resources: {
      en: { translation: enTranslations },
      fr: { translation: frTranslations },
      'en-ie': { translation: ie },
      'en-au': { translation: aus },
      de: { translation: deTranslations },
    },
    fallbackLng: 'en',
    saveMissing: true,
    lowerCaseLng: true,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
